<template>
  <page-template :title="$t('mapBase')" class="chart-history-page">
    <div class="chart-history-page--wrapper">
      <button @click="getDefaultData" type="button" class="reset_sort-btn">{{ $t('pageBase.defaultSort') }}</button>

      <div v-if="!isDesktopScreen" class="card_wrapper">
        <div v-for="(chart, index) in chartHistory" :key="chart.chartId" class="card_item">
          <history-item
            @showDelete="showDeleteDialog"
            :index="index"
            :data="chart"
            :active="chartId === chart.chartId"
            @selected="selectItem"
            @edit-current="editCurrent"
          />
        </div>
      </div>

      <history-table
        v-else
        @sort="sortBy"
        @selected="selectItem"
        @edit-current="editCurrent"
        @show-delete="showDeleteDialog"
      />
      <delete-notification-container />
      <AstroButton class="new_map-btn" :title="$t('newMap')" @click="$router.push('/new-map')" />
      <infinite-loading ref="loader" @infinite="onDataLoad">
        <div slot="spinner">
          <div class="chart-history-page__loading">
            <v-progress-circular indeterminate color="primary" class="loading"></v-progress-circular>
          </div>
        </div>
        <span slot="no-more" />
        <span slot="no-results" />
      </infinite-loading>
    </div>
  </page-template>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import '@/plugins/masonry';
import { PageTemplate } from '@/elements/components/page';
import DeleteNotificationContainer from './DeleteNotificationContainer';
import AstroButton from '@/elements/components/AstroButton';
const HistoryItem = () => import('./HistoryItem');
const HistoryTable = () => import('./HistoryTable');

export default {
  name: 'ChartHistoryPage',
  components: {
    AstroButton,
    PageTemplate,
    HistoryItem,
    HistoryTable,
    DeleteNotificationContainer,
    InfiniteLoading,
  },
  data() {
    return {
      sort: 'DATE_CREATED',
      order: 'DESC',
    };
  },
  computed: {
    ...mapState('ChartModule', ['chartId', 'chartHistoryItems']),
    ...mapGetters('ChartModule', ['chartHistory', 'chartHistoryPageNumber', 'chartHistoryNoMore']),
    ...mapGetters('UserModule', ['isLoggedIn']),
    isDesktopScreen() {
      return this.$mq === 'exlg' || this.$mq === 'hr';
    },
  },
  methods: {
    ...mapActions('ChartModule', ['getChartHistory', 'loadChart', 'sortChart', 'increaseChartHistoryPageNumber']),
    ...mapActions('ChartModule', ['queueDeleteHistoryItem', 'revertDeleteHistoryItem']),
    async showDeleteDialog(item) {
      if (this.$i18n.locale === 'en') {
        const isSubmitted = await this.$showConfirmDialog(
          `Are you sure you want to remove ${item.mapName} from the database?`,
          'Yes, delete',
          'Keep',
          '',
          this.queueDeleteHistoryItem,
        );
        if (isSubmitted) {
          this.queueDeleteHistoryItem(item);
        }
      } else if (this.$i18n.locale === 'de') {
        const isSubmitted = await this.$showConfirmDialog(
          `Möchten Sie wirklich diese ${item.mapName} laus der Datenbank löschen?`,
          'Ja, löschen',
          'Lassen',
          '',
          this.queueDeleteHistoryItem,
        );
        if (isSubmitted) {
          this.queueDeleteHistoryItem(item);
        }
      }
    },

    async sortBy(sort, order) {
      await this.sortChart({
        number: 0,
        size: this.chartHistory.length,
        sort: sort,
        order: order,
      });
    },
    async getDefaultData() {
      await this.sortChart({
        number: 0,
        size: this.chartHistory.length,
        sort: this.sort,
        order: this.order,
      });
    },
    async selectItem(chart) {
      if (chart.chartId === this.chartId) {
        return;
      }

      await this.loadChart(chart);
      await this.$router.push({ name: 'natal-chart' });
    },

    async editCurrent(chart) {
      /*if (chart.chartId === this.chartId) {
        return;
      }*/

      await this.loadChart(chart);
      await this.$router.push({ name: 'edit-map' });
    },
    async onDataLoad($state) {
      if (!this.chartHistoryNoMore) {
        await this.getChartHistory({
          number: this.chartHistoryPageNumber,
          size: 20,
          sort: this.sort,
          order: this.order,
        });
        this.increaseChartHistoryPageNumber();
        $state.loaded();
      } else {
        $state.complete();
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.new_map-btn
  position: absolute
  right: 30px
  top: 20px
  @media screen and (max-width: 991px)
    display: none
  @media screen and (max-width: 768px)
    position: static
    width: 100%
.chart-history-page
  position: relative
  max-height: 100vh
  overflow: auto

  &--wrapper
    display: flex
    flex-direction: column
    flex: 1
    @media screen and (max-width: 768px)
      flex-direction: column

  &__loading
    margin-top: base-unit(15)
.row
  display: flex
  flex-wrap: wrap
  flex: 1 1 auto
  margin: 0

.reset_sort-btn
  font-size: base-unit(16)
  color: blue-color('primary')
  display: block
  margin-left: auto
  margin-bottom: base-unit(20)
  @media screen and (max-width: 991px)
    display: none

.card_wrapper
  width: 100%
  display: flex
  flex-wrap: wrap
  column-gap: base-unit(10)
  .card_item
    width: 49%
    @media screen and (max-width: 630px)
      width: 100%
    .item
      width: 100%
</style>
