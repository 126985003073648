<template>
  <notification-container :notifications="chartsToDelete" id-prop="chartId" v-slot="{ notification }">
    <delete-notification-item :chart="notification" />
  </notification-container>
</template>
<script>
import { mapState } from 'vuex';

import DeleteNotificationItem from './DeleteNotificationItem';
import { NotificationContainer } from '@/elements/components/notification';

export default {
  name: 'DeleteNotificationContainer',
  components: { NotificationContainer, DeleteNotificationItem },
  computed: {
    ...mapState('ChartModule', ['chartsToDelete']),
  },
};
</script>
